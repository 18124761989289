$(document).ready(function () {

  var cookieDuration = 90;                    // Number of days before the cookie expires, and the banner reappears
  var cookieName = 'complianceCookie';        // Name of our cookie
  var cookieValue = 'on';                     // Value of cookie
  var analyticsId = 'G-HJKTEJNH37';           // Google Analytics tracking id for landingpage
  var adwordsId = 'AW-971856410';             // Google AdWords conversion id

  if (navigator.doNotTrack != 1 && navigator.doNotTrack != "yes" && window.doNotTrack != 1 && navigator.msDoNotTrack != 1) {
    // Do not track is off
    if (getCookie(cookieName) === null) {
      showBanner();
    }
    if (getCookie(cookieName) == cookieValue) {
      addTracking(analyticsId);
      addTracking(adwordsId);
    }

    $("#cookies-accept").on("click", function () {
      setCookie(cookieName, 'on', cookieDuration); // Create the cookie
      closeBanner();
      addTracking(analyticsId);
      addTracking(adwordsId);
    });

    $("#cookies-reject").on("click", function () {
      setCookie(cookieName, 'off', cookieDuration); // Create the cookie
      closeBanner();
    });
  } else {
    // When do not track is enabled do ntch show cookie banner and do not add tracking
    eraseCookie(cookieName); // Delete the cookie
  }

});



function addTracking(analyticsId) {
  // Tracking using Google tag manager
  var gascript = document.createElement("script");
  gascript.async = true;
  gascript.src = "https://www.googletagmanager.com/gtag/js?id=" + analyticsId;
  document.getElementsByTagName("head")[0].appendChild(gascript, document.getElementsByTagName("head")[0]);
  window.dataLayer = window.dataLayer || [];
  function gtag() { dataLayer.push(arguments); }
  gtag('js', new Date());
  gtag('config', analyticsId, { 'anonymize_ip': true });
  // alert("Tracking active!");
}

function showBanner() {
  var bodytag = document.getElementsByTagName('body')[0];
  var div = document.createElement('div');
  div.setAttribute('id', 'cookie-law');
  div.innerHTML = '<div id="cookiebanner" class="p-1"><div class="text-center"><span>Dürfen wir Goolge Analytics verwenden, um die Seite zu verbessern? Hierzu müssten Cookies erlaubt werden.</span> <a href="datenschutz.html" id="cookies-more">Datenschutzerklärung</a> <button class="btn btn-default" id="cookies-reject">Ablehnen</button> <button class="btn btn-primary" id="cookies-accept">Erlauben</button></div></div>';
  bodytag.appendChild(div); // Adds the Cookie Law Banner just before the closing </body> tag
  // or
  // bodytag.insertBefore(div,bodytag.firstChild); // Adds the Cookie Law Banner just after the opening <body> tag
  document.getElementsByTagName('body')[0].className += ' cookiebanner'; //Adds a class tothe <body> tag when the banner is visible
}

function closeBanner() {
  var element = document.getElementById('cookie-law');
  element.parentNode.removeChild(element);
}

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function eraseCookie(name) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

